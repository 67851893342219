import React, { useEffect, useState } from 'react';
import {Row, Col} from 'react-bootstrap';
import ClickImg from '../../assets/img/click.svg';
import CsvDownloader from 'react-csv-downloader';
import csvImg from '../../assets/img/csv.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import BarChart from '../../components/BarChart';
import LogoSite from '../../assets/img/bannerMobile.svg';
import telImg from '../../assets/img/call.svg';
import mailImg from '../../assets/img/mail.svg';
import toolImg from '../../assets/img/tool.svg';
import CheckImg from '../../assets/img/select.svg';
import AC from './Bandeiras/AC.svg';
import AL from './Bandeiras/AL.svg';
import AM from './Bandeiras/AM.svg';
import AP from './Bandeiras/AP.svg';
import BA from './Bandeiras/BA.svg';
import BR from './Bandeiras/BR.svg';
import CE from './Bandeiras/CE.svg';
import DF from './Bandeiras/DF.svg';
import ES from './Bandeiras/ES.svg';
import GO from './Bandeiras/GO.svg';
import MA from './Bandeiras/MA.svg';
import MG from './Bandeiras/MG.svg';
import MS from './Bandeiras/MS.svg';
import MT from './Bandeiras/SP.svg';
import PA from './Bandeiras/PA.svg';
import PB from './Bandeiras/PB.svg';
import PE from './Bandeiras/PE.svg';
import PI from './Bandeiras/PI.svg';
import PR from './Bandeiras/PR.svg';
import RJ from './Bandeiras/RJ.svg';
import RN from './Bandeiras/RN.svg';
import RO from './Bandeiras/RO.svg';
import RR from './Bandeiras/RR.svg';
import RS from './Bandeiras/RS.svg';
import SC from './Bandeiras/SC.svg';
import SE from './Bandeiras/SE.svg';
import SP from './Bandeiras/SP.svg';
import TO from './Bandeiras/TO.svg';
import imgPactoGlobal from '../../assets/img/logo-pacto-global.jpg';
import imgAssocPaulista from '../../assets/img/logo-apm.jpg';
import imgSaesp from '../../assets/img/logo-saesp.jpg';
import rodapeImg from '../../assets/img/footer.png';

import api from '../../services/api';
import './AppMobile.css';


let grafico04 = [];
let grafico05 = [];
let grafico13 = [];
let grafico15 = [];
let grafico16 = [];
let dataCSV = [];
let status04 = 'not';
let status05 = 'not';
let status13 = 'not';
let status15 = 'not';
let status16 = 'not';
let caminhoBandeira = BR;
let municipio = '';
let ufBR = '';

function App() {

  const elementoSVG = <FontAwesomeIcon icon={faSearch} />
  const [status, setStatus] = useState('not')
  const [estado, setEstado] = useState([]);
  const [citys, setCity] = useState([]);

  const columns = [
    {
      id: 'col00',
      displayName: 'Municipio'
    },
    {
      id: 'col01',
      displayName: 'Estado'
    }, {
      id: 'col02',
      displayName: 'Ano Referencia'
    }, {
      id: 'col03',
      displayName: 'Prestador'
    }, {
      id: 'col04',
      displayName: 'Sigla Prestador'
    },
    {
      id: 'col05',
      displayName: 'Abrangencia'
    },
    {
      id: 'col06',
      displayName: 'Tipo Serviço'
    }, {
      id: 'col07',
      displayName: 'Natureza Juridica'
    }, {
      id: 'col08',
      displayName: 'DESPERDÍCIO DE ÁGUA (%)'
    },
    {
      id: 'col09',
      displayName: 'TARIFA DA ÁGUA (R$/m³)'
    },
    {
      id: 'col010',
      displayName: 'TARIFA DE ÁGUA E ESGOTO (R$/m³)'
    },
    {
      id: 'col011',
      displayName: 'COLETA DE ESGOTO (%)'
    },
    {
      id: 'col012',
      displayName: 'TRATAMENTO DE ESGOTO (%)'
    }
  ];

  async function loadCity(data) {
   /* Função que ira realizar a chamada a API para carregar as cidades do municipio,
    e a imagem do estado selecionado.
   */
    const uf = data.split("-");
    ufBR = uf[1];
    if(ufBR === 'AC'){
      caminhoBandeira = AC;
    }else if(ufBR === 'AL'){
      caminhoBandeira = AL;
    }else if(ufBR === 'AM'){
      caminhoBandeira = AM;
    }else if(ufBR === 'AP'){
      caminhoBandeira = AP;
    }else if(ufBR === 'BA'){
      caminhoBandeira = BA;
    }else if(ufBR === 'CE'){
      caminhoBandeira = CE;
    }else if(ufBR === 'DF'){
      caminhoBandeira = DF;
    }else if(ufBR === 'ES'){
      caminhoBandeira = ES;
    }else if(ufBR === 'GO'){
      caminhoBandeira = GO;
    }else if(ufBR === 'MA'){
      caminhoBandeira = MA;
    }else if(ufBR === 'MG'){
      caminhoBandeira = MG;
    }else if(ufBR === 'MS'){
      caminhoBandeira = MS;
    }else if(ufBR === 'MT'){
      caminhoBandeira = MT;
    }else if(ufBR === 'PA'){
      caminhoBandeira = PA;
    }else if(ufBR === 'PB'){
      caminhoBandeira = PB;
    }else if(ufBR === 'PE'){
      caminhoBandeira = PE;
    }else if(ufBR === 'PI'){
      caminhoBandeira = PI;
    }else if(ufBR === 'PR'){
      caminhoBandeira = PR;
    }else if(ufBR === 'RJ'){
      caminhoBandeira = RJ;
    }else if(ufBR === 'RN'){
      caminhoBandeira = RN;
    }else if(ufBR === 'RO'){
      caminhoBandeira = RO;
    }else if(ufBR === 'RR'){
      caminhoBandeira = RR;
    }else if(ufBR === 'RS'){
      caminhoBandeira = RS;
    }else if(ufBR === 'SC'){
      caminhoBandeira = SC;
    }else if(ufBR === 'SE'){
      caminhoBandeira = SE;
    }else if(ufBR === 'SP'){
      caminhoBandeira = SP;
    }else if(ufBR === 'TO'){
      caminhoBandeira = TO;
    }else{
      caminhoBandeira = BR;
    }
    try {
      const response = await api.get(`estado/${uf[1]}`);
      setCity(response.data.cidades);

    } catch (error) {
      console.log(error);
    }
  };


  async function loadUF() {
    // realiza a chamada a API para carregar os dados do estado, nome, uf, população.
    try {
      const response = await api.get('estado');
      setEstado(response.data);

    } catch (error) {
      console.log(error)
    }
  }

  // um efeito colateral, chama a função apos assim que a pagina for carregada 
  useEffect(() => {
    loadUF();
    
  }, []);

  async function handleGrafico(e){
     e.preventDefault();
     //status definido como not (não ira mostrar os graficos)
     setStatus('not');
     //criar arrys que irao receber os valores
     grafico04 = [];
     grafico05 = [];
     grafico13 = [];
     grafico15 = [];
     grafico16 = [];
     dataCSV = [];
 
     let none04 = 0;
     let none05 = 0;
     let none13 = 0;
     let none15 = 0;
     let none16 = 0;
     //Objeto data sera usado como parametros para a chamada a API
     const data = {
       //pegando dados do Local Storage
       uf: ufBR,
       city: municipio
     }
 
     //Chamada a API
     const response = await api.post('cidade', data);
     //pega o data da resposta
     const aux = response.data;
     let tam = aux.length;
 
     //necessario criar a primeira linha de cada array, a primeira linha sera interpretada como o indices do grafico pelo chart da google
     grafico04 = [["Ano", "(R$/m³)", {
       sourceColumn: 0,
       role: 'annotation',
       type: 'string',
       calc: 'stringify',
     }]];
     grafico05 = [["Ano", "(R$/m³)", {
       sourceColumn: 0,
       role: 'annotation',
       type: 'string',
       calc: 'stringify',
     }]];
     grafico13 = [["Ano", "(%)", {
       sourceColumn: 0,
       role: 'annotation',
     }]];
     grafico15 = [["Ano", "(%)", {
       role: 'annotation',
     }]];
     grafico16 = [["Ano", "(%)", {
       role: 'annotation',
     }]];
 
     //Preenchendo os array com o forEach
     aux.forEach(element => {
       //o AnoReferencia precisa ser concatenado com uma String (""), caso contrario o GoogleChart ira mostrar numeros maiores q mil seguidos de K (ex 2019 => 2.019k)
       if (element.IN004 !== 0 && element.AnoReferencia >= 2008 && element.IN004 > 0 && element.IN004 <= 100 ) {
         grafico04.push(["" + element.AnoReferencia, element.IN004,"R$ "+element.IN004]);
         status04 = 'ready';
       } else {
         none04++;
       }
 
       if (element.IN005 !== 0 && element.AnoReferencia >= 2008 && element.IN005 > 0 && element.IN005 <= 100 ) {
         grafico05.push(["" + element.AnoReferencia, element.IN005,"R$ "+element.IN005]);
         status05 = 'ready';
       } else {
         none05++;
       }
 
       if (element.IN013 !== 0 && element.AnoReferencia >= 2008 && element.IN013 > 0 && element.IN013 <= 100 ) {
         grafico13.push(["" + element.AnoReferencia, element.IN013,element.IN013+"%"]);
         status13 = 'ready';
       } else {
         none13++;
       }
 
       if (element.IN015 !== 0 && element.AnoReferencia >= 2008 && element.IN015 > 0 && element.IN015 <= 100 ) {
         grafico15.push(["" + element.AnoReferencia, element.IN015,element.IN015+"%"]);
         status15 = 'ready';
       } else {
         none15++;
       }
 
       if (element.IN016 !== 0 && element.AnoReferencia >= 2008 && element.IN016 > 0 && element.IN016 <= 100 )  {
         grafico16.push(["" + element.AnoReferencia, element.IN016,element.IN016+"%"]);
         status16 = 'ready';
       } else {
         none16++;
       }
 
       dataCSV.push({
         col00: element.Municipio,
         col01: element.Estado,
         col02: element.AnoReferencia,
         col03: element.Prestador,
         col04: element.SiglaPrestador,
         col05: element.Abrangencia,
         col06: element.TipoServiço,
         col07: element.NaturezaJuridica,
         col08: '' + element.IN013,
         col09: '' + element.IN005,
         col010: '' + element.IN004,
         col011: '' + element.IN015,
         col012: '' + element.IN016
       });
 
     });
 
     if (none04 === tam) {
       status04 = 'not';
     }
 
     if (none05 === tam) {
       status05 = 'not';
     }
     if (none13 === tam) {
       status13 = 'not';
     }
 
     if (none15 === tam) {
       status15 = 'not';
     }
     if (none16 === tam) {
       status16 = 'not';
     }
 
     //se qualquer ums dos array for maior que um muda o Status para exibir os graficos
     if (grafico04.length > 1 || grafico05.length > 1 || grafico13.length > 1 || grafico15.length > 1 || grafico16.length > 1) {
       setStatus('yes');
     }
  }
function manipulaSVG(event){
  event.preventDefault();

}


  return (
    <div className="principal">
      <header id="header-mobile" >
        <img src={LogoSite} className="bannerPrincipal" />
      </header>
      <main id="main-mobile">
        <hr className="hrAzul" />
        <p id="titulo-descricao">Indicadores de Água e Esgotos - Brasil</p>
        <p id="descricao">
          O projeto Indicadores de Águas e Esgotos – Brasil é mais uma iniciativa social do Conselho Regional de Administração de São Paulo - CRA-SP, com o objetivo de disponibilizar o acesso às informações sobre águas e esgotos de todos os municípios do Brasil, servindo como uma poderosa ferramenta de trabalho para a gestão, fiscalização e estudo destinado aos gestores públicos, pesquisadores, imprensa e, principalmente, para a sociedade, pois a água sempre foi, é, e sempre será o mais importante recurso natural para a humanidade. Por essa razão, seu uso consciente e sustentável deve ser a demanda prioritária de toda comunidade global.
       <br />
          <br />
       Os dados presentes na plataforma são oficiais, disponibilizados pela Secretaria Nacional de Saneamento (SNS), do Ministério do Desenvolvimento Regional, e apresentados pelo CRA-SP em série histórica, de todos os municípios do Brasil, de forma clara e de fácil entendimento.
       </p>
        <p id="descricao-centro">
          A plataforma disponibiliza os indicadores de:<br />
          <br />
          Desperdício de Água (%)<br />
          Tarifa da Água (R$/m³)<br />
          Tarifa de Água e Esgoto (R$/m³)<br />
          Coleta de Esgoto (%)<br />
          Tratamento de Esgoto (%)<br />
        </p>

        <p id="descricao">
          Buscando oferecer uma melhor experiência de navegação nesta plataforma, os gráficos exibem apenas os dados dos últimos dez anos apresentados pelos municípios, entretanto, no botão "Faça o download dos dados completos", ao final da página, você consegue acessar todos os dados relacionados disponíveis.
       <br />
          <br />Com o apoio da Rede Brasil do Pacto Global – ONU e a parceria da APM - Associação Paulista de Municípios, o projeto lançado pelo CRA-SP em 2019 e até então denominado Gestão das Águas Paulistas, ganha agora alcance nacional, especialmente, após a recente aprovação, em junho de 2020, pelo Congresso Nacional, do Marco Legal do Saneamento Básico.

       </p>
        <p id="descricao-centro">
          Adm. Roberto Carvalho Cardoso<br />
            Presidente
       </p>

        <hr className="hrAzul" />
        <p id="titulo-descricao">Saiba como acessar os dados</p>

        <div className="instrucoes">
          <h3 className="instrucaoNum">1</h3>
          <img src={CheckImg} className="instrucaoLogo" />
          <p>Selecione um estado abaixo</p>
        </div>

        <select className="selecaoUF" name="estado" id="estado" onChange={e => { loadCity(e.target.value) }} >
          <option className="opacaoUF" defaultValue >Escolha um estado</option>
          {//aqui sera usado a variavel citys, assim que re
            estado.map(city => (
              <option key={city.populacao} value={city.uf}>{city.nome}</option>
            ))
          }

        </select>
        <img src={caminhoBandeira} className="bandeira" />

        
       
        <br />
        <div className="instrucoes">
          <h3 className="instrucaoNum">2</h3>
          <img src={CheckImg} className="instrucaoLogo" />
          <p>Selecione um município abaixo</p>
        </div>

        <select className="selecaoUF" name="cidade" id="cidade" onChange={e => { municipio = e.target.value }}>
          <option className="opacaoUF" defaultValue >Escolha um município</option>
          {
            citys.map(city => (
              <option key={city} value={city}>{city}</option>
            ))
          }

        </select>
        
        <br />
        <div className="instrucoes">
          <h3 className="instrucaoNum">3</h3>
          <img src={ClickImg} className="instrucaoLogo" />
          <p>Clique no botão buscar</p>
        </div>

        <form  onSubmit={handleGrafico}>
          <button className="btnBuscaRosaMobile" type="submit">
            Buscar {elementoSVG}
          </button>
          <br />
          <Row className="justify-content-md-center">
              {status === 'yes' ? (
                <section className="graficos" >
                  <Row className="justify-content-md-center">
                    <Col lg={12}>
                      <p className="indicesFontesBlue indicesFontes">DESPERDÍCIO DE ÁGUA (%)</p>
                      <BarChart dataMapa={grafico13} title={""} corMapa={'#0053a2'} status={status13} tituloVertical={"(%)"} />
                    </Col>
                  </Row>
                  <br/>
                  <Row className="justify-content-md-center">
                    <Col lg={12}>
                      <p className="indicesFontesGreen indicesFontes">TARIFA DA ÁGUA (R$/m³)</p>
                      <BarChart dataMapa={grafico05} title={""} corMapa={'#00933b'} status={status05} tituloVertical={"(R$/m³)"} />
                    </Col>
                  </Row>
                  <br/>
                  <Row className="justify-content-md-center">
                  <Col lg={12}>
                      <p className="indicesFontesBrown indicesFontes">TARIFA DE ÁGUA E ESGOTO (R$/m³)</p>
                      <BarChart dataMapa={grafico04} title={""} corMapa={'#ffa200'} status={status04} tituloVertical={"(R$/m³)"} />
                    </Col>
                  </Row>
                  <br/>
                  <Row className="justify-content-md-center">
                    <Col lg={12}>
                      <p className="indicesFontesYellow indicesFontes">COLETA DE ESGOTO (%)</p>
                      <BarChart dataMapa={grafico15} title={""} corMapa={'#456bd4'} status={status15} tituloVertical={"(%)"} />
                    </Col>
                  </Row>
                  <br/>
                  <Row className="justify-content-md-center">
                    <Col lg={12}>
                      <p className="indicesFontesLightBlue indicesFontes">TRATAMENTO DE ESGOTO (%)</p>
                      <BarChart dataMapa={grafico16} title={""} corMapa={'#76b3df'} status={status16} tituloVertical={"(%)"} />
                    </Col>
                  </Row>
                  <br/>
                </section>
              ) : (<span>você ainda não selecionou nenhuma cidade</span>)}
            </Row>
			<CsvDownloader 
                    className="btnCSVMobile"
                      filename="ResumoAguasBrasil"
                      separator=";"
                      wrapColumnChar="'"
                      columns={columns}
                      datas={dataCSV}
                      text="Baixe o CSV" 
                      >Faça download dos dados completos<img src={csvImg} className="logoCSV" alt="icone de Download"/></CsvDownloader>
                      <br/>
                      <p className="infoCSV">Algumas cidades apresentam índice negativo, pois são atendidas por prestadoras de serviço regionais que contabilizam as perdas apenas de forma agregada.</p>
                      

        </form>

        

      </main>
      <footer >

        <section className="apoiadores">
          <hr className="hrAzul" />
          <h2 className="h1Intro">Apoio</h2>
          <section className="logosApoio">
            <img src={imgPactoGlobal} className="imgApoiadores" />

            <img src={imgAssocPaulista} className="imgApoiadores" />

            <img src={imgSaesp} className="imgApoiadores" />
          </section>
        </section>
        <section className="labelContato">
          <hr className="hrBranco" />
          <h2>Contatos</h2>
        </section>
        <section className="contatos">
          <div className="contatos01">
            <p><img src={telImg} className="imgFooter" />Tel: (+55 11) 3087-3200</p>
            <p><img src={mailImg} className="imgFooter" />atendimento@crasp.gov.br</p>
          </div>
          <div>
            <p><img src={toolImg} className="imgFooter" />Rua Estados Unidos, 889 Jardim América </p>
            <p className="iconLocaliza">CEP: 01427-001 - São Paulo/SP</p>
          </div>
        </section>
        <img src={rodapeImg} className="imgRodape" />
      </footer>

    </div>



  );
}

export default App;