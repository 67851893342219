import React, { useState } from 'react';
import { Chart } from "react-google-charts";
import Component from "react-component-component";
import { Col, Container, Form } from 'react-bootstrap';
import MapaImg from '../../assets/img/brazil-map.svg';
import CheckImg from '../../assets/img/select.svg';
import ClickImg from '../../assets/img/click.svg';
import './styles.css';

import api from '../../services/api';
import useForm from '../../hooks/useForm';


export default function Mapa() {
    //variavel usada para exibir as cidades na lista de seleção
    const [citys, setCity] = useState([]);

    const [{ values }, handleChange, handleSubmit] = useForm();

    async function graficoCidade() {
        localStorage.setItem("cidade", values.cidade);
    };

    //Função consulta o Estado
    async function consultaEstado(uf) {
        try {
            //Limpa o Local Storage,e faz a chamada a API com o UF que recebel
            localStorage.clear();
            const response = await api.get(`estado/${uf}`);
            //seta a varialvel citys atualizando ela com as cidades que foram retornadas
            setCity(response.data.cidades);
            //salva na LocalStorage o uf recebido
            localStorage.setItem('uf', uf);

        } catch (err) {
            //Casso de algum erro acima
            alert('Falha na consulta, UF não localizado');
        }
    }


    return (
        <>
            <Component
                initialState={
                    { dataLoadingStatus: 'loading', chartData: [] }
                }
                didMount={
                    async function (component) {
                        localStorage.clear();
                        //realiza chamada na API
                        const response = await api.get(`estado`);
                        const aux = response.data;
                        //Cria o Array que sera usado para fazer o mapa
                        const chartData = [['Estado', 'populacao']]
                        aux.forEach(element => {
                            chartData.push([element.uf, element.populacao])
                        }
                        );
                        component.setState({
                            //informa que o mapa esta printo para ser renderizado
                            dataLoadingStatus: 'ready',
                            chartData: chartData,
                        })
                    }}
            >
                {component => {
                    return component.state.dataLoadingStatus === 'ready'   ?(
                        <>
                            <Container>
                                <Chart
                                
                                    chartType="GeoChart"
                                    data={component.state.chartData}
                                    options={{
                                        region: 'BR',
                                        resolution: 'provinces',
                                        colorAxis: {
                                            minValue: 0,
                                            colors: ['#87CEEB', '#0000CD'],
                                        },
                                        legend: 'none',
                                    }}
                                    chartEvents={[
                                        {
                                            eventName: 'select',
                                            callback: ({ chartWrapper }) => {
                                                const chart = chartWrapper.getChart()
                                                //recebe a seleção(quando o usuario selecionar algum estado no mapa)
                                                const selection = chart.getSelection()
                                                if (selection.length === 1) {
                                                    // quando a seleção for igual a 1
                                                    const [selectedItem] = selection
                                                    const dataTable = chartWrapper.getDataTable()
                                                    const { row } = selectedItem
                                                    //recupera o valor da dataTable (array), utiliza o split para remover o BR do nome
                                                    let est = (dataTable.getValue(row, 0)).split("-");
                                                    let es = est[1];
                                                    // chama a função consultaEstado(passando o UF recebido);
                                                    consultaEstado(es);
                                                }
                                            },
                                        },
                                    ]}
                                />
                                  <div className="escalaMapa">
                                    <section className="scaleMap">
                                        <div className="pointScale"></div>
                                        <div className="pointScale"></div>
                                        <div className="pointScale"></div>
                                        <div className="pointScale"></div>
                                        <div className="pointScale"></div>
                                        <div className="pointScale"></div>
                                    </section>
                                    <section className="scaleMapValues">
                                        <div >0</div>
                                        <div >10</div>
                                        <div >20</div>
                                        <div >30</div>
                                        <div >40</div>
                                        <div >50</div>
                                    </section>
                                    </div>
                                <label className="infoPop" >Habitantes por milhão</label></Container>
                        </>

                    ) : (
                            <div>Carregando o mapa ...</div>
                        )
                }
                }
            </Component>
            <br/>
            <br/>
            <div className="instrucoesUso">
                <center>
                    <hr className="hrAzul" />
                    <h2 className="h1Intro" >Saiba como acessar os dados</h2>
                </center>
            </div>

            <section className="intrucoesMapa container">
                <div className="mapy">
                    <h3 className="numeroInstrucao  especial">1</h3>
                    <img src={MapaImg} className="imgSaibaComo" />
                    <p>Selecione um estado no mapa</p>
                </div>
                <div className="checky">
                    <h3 className="numeroInstrucao">2</h3>
                    <img src={CheckImg} className="imgSaibaComo" />
                    <p>Selecione um município nas opções abaixo do mapa
                </p>
                </div>
                <div className="clicky">
                    <h3 className="numeroInstrucao">3</h3>
                    <img src={ClickImg} className="imgSaibaComo" />
                    <p>Clique no botão buscar
                </p>
                </div>
            </section>
            <br />
            <Form >
                <Form.Group controlId="exampleForm.SelectCustom" className="container" >
                    <Form.Row className="justify-content-md-center">
                        <Col lg={12}>
                            <select className="selecao" name="cidade" id="cidade" onChange={handleChange} onClick={handleSubmit(graficoCidade)}>
                                <option className="opacaoDefaul" defaultValue >Escolha um Município</option>
                                {//aqui sera usado a variavel citys, assim que re
                                    citys.map(city => (
                                        <option key={city} value={city}>{city}</option>

                                    ))}
                            </select>
                        </Col>
                    </Form.Row>
                </Form.Group>
            </Form>
        </>
    );
}