import React from 'react';
import { Chart } from "react-google-charts";
import MapaComponent from "react-component-component";

export default function BarChart(props) {
    let graficoOK;
   // let valueMax;
    props.dataMapa.forEach(element => {
        


/*
        if(!isNaN(element[1])=== true){
           if(element[1] > 100){
              valueMax = element[1]+20;
           }else{
            valueMax = 100; 
           }
        }
        */
        element.forEach(element => {
            

            if (element === "null%" || element === "null" || element === "R$ null") {

                graficoOK = 'not';
            } else {

                graficoOK = 'ready';
            }

        });
    });

    return (
        <MapaComponent
            initialState={
                { dataLoadingStatus: 'loading', chartData: [] }
            }
            didMount={

                async function (component) {
                    component.setState({
                        dataLoadingStatus: props.status,
                        chartData: props.dataMapa,
                    })
                }
            }
        >
            {component => {
                return component.state.dataLoadingStatus === 'ready' && graficoOK === 'ready' ? (
                    <Chart
                        width={'100%'}
                        height={'60vh'}
                        chartType="ColumnChart"
                        loader={<div>Loading Chart</div>}
                        data={component.state.chartData}
                        options={{
                            title: props.title,
                            chartArea: {
                                width: '90%',
                                height: '75%'
                            },
                            annotations: {
                                textStyle: {
                                    fontSize: 14,
                                    bold: true,
                                    marginTop: 20
                                },
                                format:'0.0',
                            },
                            colors: [props.corMapa],
                            vAxis: {
                                title: props.tituloVertical,
                                titleTextStyle: {
                                    bold: true,
                                },
                            
                                viewWindow:{
                                    min: 0,
                                    max:100
                                },
                                
                                textStyle:{ 
                                    fontSize: 16, 
                                    bold: true, 
                                }
                               
                            },
                            hAxis: {
                                title: "Anos",
                                titleTextStyle: {
                                    bold: true,
                                },
                                
                                textStyle:{ 
                                    fontSize: 16, 
                                    bold: true, 
                                }
                                
                            },
                            legend: 'none',
                        }}
                        rootProps={{ 'data-testid': '' }}
                    />
                ) : (
                        <><center><label> Não possui dados para esse grafico</label></center></>
                    )
            }
            }
        </MapaComponent>

    );
}
