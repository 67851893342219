import React, { Component } from 'react';
import {isMobile} from 'react-device-detect';
import App from './pages/browser/App.js';
import AppMobile from './pages/mobile/AppMobile.js';

 
function Redireciona(){
    if (isMobile) {
        return <AppMobile/>
    }
    return <App/>
};
 

export default Redireciona;